import React from 'react'

import { Inline, List, ListItem, Paragraph } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Placeholder } from '../../components/Placeholder'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Inline"
    components={[{ component: Inline, restElement: true }]}
  >
    <Section>
      <List variant="unordered">
        <ListItem>
          Elementtien välin voi määritellä vaihtuvaksi eri kokoisilla näytöillä.
        </ListItem>
        <ListItem>
          Jos kyseessä on lista, voi <Code>as</Code> propilla vaihtaa
          juurielementin järjestetyksi (<Code>ol</Code>) tai järjestämättömäksi
          listaksi (<Code>ul</Code>).
        </ListItem>
      </List>
    </Section>
    <Section title="Väli komponenttien ympärillä">
      <Paragraph>
        Komponenttien välit voidaan määritellä <Code>gap</Code>:n arvoa
        muuttamalla.
      </Paragraph>
      <Playground>
        <Inline
          gap={{ xs: 'xxxs', md: 'xs', xl: 'md' }}
          wrap="wrap"
        >
          {[...Array(12).keys()].map(i => (
            <Placeholder
              key={`a_${i}`}
              width={i % 2 === 0 ? 4 : 5}
            />
          ))}
        </Inline>
      </Playground>
      <Paragraph>
        Komponenttien välit voidaan määritellä eri mittaisiksi sekä
        ylä-/alapuolelle (<Code>gapY</Code>) että sivuille (<Code>gapX</Code>).
      </Paragraph>
      <Playground>
        <Inline
          gapX="md"
          gapY="none"
          wrap="wrap"
        >
          {[...Array(12).keys()].map(i => (
            <Placeholder
              key={`b_${i}`}
              width={i % 2 === 0 ? 4 : 5}
            />
          ))}
        </Inline>
      </Playground>
    </Section>
    <Section title="Tasaus">
      <Paragraph>
        Komponentit voidaan tasata monin eri tavoin ja tasausta voidaan muuttaa
        näyttökoon perusteella <Code>alignItems</Code> ja{' '}
        <Code>justifyContent</Code> -propseilla.
      </Paragraph>
      {['start', 'center', 'end'].map(alignItems => (
        <Playground key={`1_${alignItems}`}>
          <Inline
            alignItems={alignItems as any}
            as="ul"
            gap="md"
            justifyContent="center"
            key={`c_${alignItems}`}
          >
            <Placeholder height={6} />
            <Placeholder />
          </Inline>
        </Playground>
      ))}
      {['center', 'start', 'end', 'between', 'around'].map(justifyContent => (
        <Playground key={`2_${justifyContent}`}>
          <Inline
            alignItems="center"
            as="ul"
            gap="md"
            justifyContent={justifyContent as any}
            key={`d_${justifyContent}`}
          >
            <Placeholder height={6} />
            <Placeholder />
          </Inline>
        </Playground>
      ))}
    </Section>
  </Content>
)

export default Page
